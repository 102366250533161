'use client'

import { breakpoint, mixin } from 'src/styles'
import { FullContainer } from 'src/components/Display/Container'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { Lock } from 'src/components/Display/Lock'
import { ProfileCard } from 'src/components/ProfileCard'
import { useMemberContext } from 'src/providers/MemberProvider'

export const CommunityProfile = () => {
  const { currentMember } = useMemberContext()

  return (
    <div>
      <Styles.FullContainer page>
        <ProfileCard />
        <Styles.DekContainer>
          <Styles.Lock />
          <Styles.Header>Only friends can see this profile.</Styles.Header>
          <Styles.Dek>
            Do you know {getMemberDisplayName(currentMember, { useFullNameWithInitial: true })}?{' '}
            <br />
            Send a friend request by clicking add friend. Once accepted, you can view&nbsp;
            {getMemberDisplayName(currentMember, { useFirstName: true })}&apos;s workout activities,
            favorite classes and schedule.
          </Styles.Dek>
        </Styles.DekContainer>
      </Styles.FullContainer>
    </div>
  )
}

const Styles = {
  FullContainer: styled(FullContainer)`
    display: flex;
    flex-direction: column;
  `,
  DekContainer: styled.div`
    ${mixin.flexCenterBoth}
    flex-direction: column;
    margin-top: 75px;
    letter-spacing: 0.005em;
    line-height: 1;
    text-align: center;
    width: 100%;
    padding: 0 4rem;

    ${breakpoint.mediumAndDown} {
      margin-top: 38px;
      padding: 0 2rem;
    }
  `,
  Lock: styled(Lock)`
    display: inline;
    width: 50px;
  `,
  Header: styled.h2`
    font-size: 46px;
    font-weight: 200;
    margin: 16px 0;
  `,
  Dek: styled.div`
    font-size: 23px;
    font-weight: 300;
    width: 100%;
    line-height: 33px;
  `,
}

export default CommunityProfile
