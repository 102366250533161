'use client'

import { MainLayout } from 'src/components/App/Layouts/MainLayout'
import { ProfileLayout } from 'src/components/App/Layouts/ProfileLayout'
import { IsMember } from 'src/components/App/RequireUser'
import { MemberProvider } from 'src/providers/MemberProvider'
import CommunityProfile from 'src/views/Profile/CommunityProfile'
import { Overview } from 'src/views/Profile/Overview'

const SocialProfile = () => (
  <MainLayout>
    <IsMember>
      <MemberProvider>
        {({ isSelf, isFriend }) =>
          isSelf || isFriend ? (
            <ProfileLayout>
              <Overview />
            </ProfileLayout>
          ) : (
            <CommunityProfile />
          )
        }
      </MemberProvider>
    </IsMember>
  </MainLayout>
)

export default SocialProfile
